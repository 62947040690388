import { Preloader } from '@msanvarov/core-components';
import { persistor, store } from '@msanvarov/store';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { SpeedInsights } from "@vercel/speed-insights/next";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { Lato } from 'next/font/google';
import Head from 'next/head';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  registerGTM,
  registerHotjar,
  registerMicrosoftClarity,
  registerVercelAnalytics,
  unami,
  registerVercelSpeed,
} from '../utils/tracking.utils';
import './styles-light.scss';
import './styles.scss';

const font = Lato({
  weight: ['100', '300', '400', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
  preload: true,
});

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    AOS.init();

    registerHotjar();
  }, []);

  useEffect(() => {
    // Page transition
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setTimeout(() => setLoading(false), 250);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);

    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, [Router]);

  return (
    <Provider {...{ store }}>
      <PersistGate loading={<Preloader />} {...{ persistor }}>
        <ThemeProvider
          {...{
            defaultTheme: 'dark',
          }}
        >
          <Head>
            <title>Abhishek Panthee - Personal Portfolio</title>
            <meta name="title" content="Abhishek Panthee" />
            <meta name="description" content="Versatile developer with expertise in building powerful, high-performance applications." />
            <meta name="keywords" content="developer, software engineer, web development, applications, AI, cybersecurity, Abhishek Panthee, Abhishek, abhishek, panthee, Abhishek Panthee , Abhishek , abhishek , panthee ,  Abishek, Abhisek, Avishek, Abhiskek, Abishek Panthee, Abhisek Panthee,  Abhishak Panthee, Abisek Panthee, Abhishk Panthee, Abhi Panthee, Abhiseh Panthee, Abhishe Panthee, Abhieshek Panthee, Abhishek Panthi, Abhiskek Panthee, Avishek Panthee, Abishek Pantee, Abishek Panth, Abhisek Panth, Abhishe Panthee, Abhishk Panthee, Avish Panthee, Abhiseck Panthee, Abhi Sek Panthee, Avisheek Panthee, Abhishek Panthi, Abhieshek Panthi, Abhishk Pantee, Abishek Pantee, Abheshik Panthee, Abhi Sekh Panthee, Abhisk Panthee, Abihsek Panthee, Abhshik Panthee, Abihsekh Panthee, Abshik Panthee, Abhskek Panthee, Abheshek Panthee, Abhishik Panthi, Abhishk Panthey, Abhisk Panth, Abhisk Sekh, Abhiscek Panthee, Abishke Panthee, Avihsek Panthee, Abishak Panthe, Abhizek Panthee, Abishek Panhtee, Abhiseck Panthee, Aabhishek Panthe" />
            <meta name="author" content="Abhishek Panthee" />
            <SpeedInsights/>

            
            <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetGroupPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted captureException loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug getPageViewId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('phc_7BI1HEy3IUAltqzqJDzcvfDHuTkJmYmFKgrCSEOsWTF', {
                  api_host:'https://us.i.posthog.com',
                  person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
              });
            `
          }}
        />


            <link
              rel="stylesheet"
              href="https://cdnjs.cloudflare.com/ajax/libs/iconoir/6.9.0/css/iconoir.min.css"
            />
            {registerMicrosoftClarity()}
            {registerGTM()}
            {unami()}
          </Head>
          <AnimatePresence mode="wait" initial={false}>
            <main className={font.className}>
              {loading ? <Preloader /> : <Component {...pageProps} />}
              {registerVercelAnalytics()}
              {registerVercelSpeed()} 
            </main>
          </AnimatePresence>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default CustomApp;
