import { Post } from '@msanvarov/store';
import Link from 'next/link';
import Moment from 'react-moment';
import { useState, useEffect } from 'react';
import { Form } from 'reactstrap';

type BlogNavbarProps = {
  posts: Post[];
  categories: string[];
  tags: string[];
};

export const BlogNavbar = ({ posts, categories, tags }: BlogNavbarProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState(posts);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filtered = posts.filter(post => {
        const titleMatch = post.metadata.title.toLowerCase().includes(searchTerm.toLowerCase());

        // Check if categories is defined and either a string or an array
        const categoryMatch = Array.isArray(post.metadata.categories)
          ? post.metadata.categories.some(category =>
              category.toLowerCase().includes(searchTerm.toLowerCase())
            )
          : typeof post.metadata.categories === 'string' &&
            post.metadata.categories.toLowerCase().includes(searchTerm.toLowerCase());

        return titleMatch || categoryMatch;
      });

      setFilteredPosts(filtered);
    }, 300); // Delay the search by 300ms to avoid rapid re-filtering

    // Cleanup function to clear the timeout when searchTerm changes
    return () => clearTimeout(timeoutId);
  }, [searchTerm, posts]);

  return (
    <div className="blog-sidebar">
      <div className="blog-sidebar-inner">
        <div className="blog-sidebar-widget search-widget">
          <div className="blog-sidebar-widget-inner" data-aos="zoom-in">
            <Form className="shadow-box" onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                placeholder="Find blog by name or category"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="submit" className="theme-btn">Find</button>
            </Form>
          </div>
        </div>
        <div className="blog-sidebar-widget recent-post-widget" data-aos="zoom-in">
          <div className="blog-sidebar-widget-inner shadow-box">
            <h3>Recent Posts</h3>
            <ul>
              {/* Display filtered posts */}
              {filteredPosts.slice(0, 8).map((post, index) => (
                <li key={index}>
                  <Link
                    as={`/posts/${post.filePath.replace(/\.mdx?$/, '')}`}
                    href={`/posts/[post]`}
                  >
                    {post.metadata.title}
                  </Link>
                  <p>
                    <Moment format="LLL">{post.metadata.modified}</Moment>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        
      </div>
    </div>
  );
};
